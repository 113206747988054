import { useQuery } from '@apollo/client';
import { Stack, TextField, Typography } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import makeStyles from '@mui/styles/makeStyles';
import { sv as locale } from '@norban/locale';
import React, { useMemo } from 'react';

import {
  BofUserAutoCompleteUserFragment,
  BofUserAutoCompleteUsersDocument,
} from '../generated/backend/graphql';
import { steelGray } from '../theme';

const L = locale.backoffice.components.UserAutocomplete;

const useStyles = makeStyles(() => ({
  detailText: {
    color: steelGray,
  },
  row: {
    alignItems: 'flex-start !important',
  },
}));

const filterOptions = createFilterOptions({
  limit: 100,
  stringify: (option: BofUserAutoCompleteUserFragment) =>
    `${option.id}${option.name}${option.phone}${option.email}`,
});

type Props = {
  dense?: boolean;
  disabled?: boolean;
  label?: string;
  limitToUserRoles?: ('admin' | 'user' | 'banned')[];
  selectedUserId: string | null;
  sortAlphabetically?: boolean;
  textFieldProps?: Partial<React.ComponentProps<typeof TextField>>;
  onSelectedUserIdChanged: (userId: string | null) => void;
} & Pick<
  React.ComponentProps<typeof Autocomplete<string | null>>,
  'className' | 'fullWidth' | 'size'
>;

const UserAutocomplete = ({
  dense = true,
  className,
  disabled = false,
  fullWidth = undefined,
  label = L.label,
  limitToUserRoles = undefined,
  selectedUserId,
  size = undefined,
  sortAlphabetically = false,
  textFieldProps = {},
  onSelectedUserIdChanged: selectedUserChanged,
}: Props) => {
  const classes = useStyles();

  const { data, loading } = useQuery(BofUserAutoCompleteUsersDocument, {
    variables: {
      filter: {
        roles: limitToUserRoles,
      },
    },
  });

  const users: BofUserAutoCompleteUserFragment[] = useMemo(() => {
    const unsorted = data?.users ?? [];

    if (!sortAlphabetically) {
      return unsorted;
    }
    return [...unsorted].sort((a, b) => {
      const aLabel = `${a?.name}, ${a?.id}`;
      const bLabel = `${b?.name}, ${b?.id}`;
      return aLabel.localeCompare(bLabel);
    });
  }, [data, sortAlphabetically]);

  const value = useMemo(
    () => users.find(user => user.id === selectedUserId) ?? null,
    [selectedUserId, users],
  );

  return (
    <Autocomplete
      loading={loading}
      loadingText={L.loading}
      className={className}
      disabled={disabled}
      filterOptions={filterOptions}
      fullWidth={fullWidth}
      getOptionLabel={option => `${option?.name} [${option.id}]`}
      multiple={false}
      options={users}
      size={size}
      value={value}
      onChange={(_, newValue) => {
        selectedUserChanged(newValue?.id ?? null);
      }}
      renderInput={params => (
        <TextField label={label} {...textFieldProps} {...params} />
      )}
      renderOption={
        dense
          ? undefined
          : ({ className, ...props }, option) => {
              return (
                <Stack
                  component="li"
                  padding={1}
                  className={`${className} ${classes.row}`}
                  {...props}
                >
                  <Typography variant="body1">{`${option.name} [${option.id}]`}</Typography>
                  <Typography
                    className={classes.detailText}
                    variant="caption"
                  >{`Tel: ${option.phone ?? '-'}`}</Typography>
                  <Typography
                    className={classes.detailText}
                    variant="caption"
                  >{`Mejl: ${option.email ?? '-'}`}</Typography>
                </Stack>
              );
            }
      }
    />
  );
};

export default UserAutocomplete;
