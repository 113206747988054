import { Button, Checkbox, FormControlLabel, Stack } from '@mui/material';
import { sv as locale } from '@norban/locale';
import React, { useCallback, useState } from 'react';

import CreateUserDialog from '../scenes/UserList/components/CreateUserDialog';

import UserAutocompleteDialog from './UserAutocompleteDialog';

type Props = {
  open: boolean;
  onCancel: () => void;
  onConfirm: (userId: string, isViewingParticipant: boolean) => void;
};

const AddFollowerDialog = ({ onCancel, open, onConfirm }: Props) => {
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [isViewingParticipant, setIsViewingParticpant] = useState(false);

  const [openCreateUserDialog, setOpenCreateUserDialog] = useState(false);

  const L = locale.backoffice.addFollowerDialog;

  const handleCancel = useCallback(() => {
    onCancel();
    setSelectedUserId(null);
  }, [onCancel]);

  const handleConfirm = useCallback(() => {
    onConfirm(selectedUserId!, isViewingParticipant);
    setSelectedUserId(null);
  }, [isViewingParticipant, onConfirm, selectedUserId]);

  return (
    <>
      <CreateUserDialog
        onClose={userId => {
          setOpenCreateUserDialog(false);

          // Select the newly created user
          if (userId) {
            setSelectedUserId(userId);
          }
        }}
        open={open && openCreateUserDialog}
      />
      <UserAutocompleteDialog
        cancel={L.cancel}
        confirm={L.confirm}
        content={
          <Stack spacing={2}>
            <Button
              onClick={() => {
                setOpenCreateUserDialog(true);
              }}
              color="primary"
            >
              {L.createUser}
            </Button>
            <FormControlLabel
              label={L.viewingParticipant}
              control={
                <Checkbox
                  checked={isViewingParticipant}
                  onChange={() => setIsViewingParticpant(!isViewingParticipant)}
                />
              }
            />
          </Stack>
        }
        dense={false}
        limitToUserRoles={['user']}
        open={open && !openCreateUserDialog}
        selectedUserId={selectedUserId}
        title={L.title}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
        onSelectedUserIdChanged={setSelectedUserId}
      />
    </>
  );
};

export default AddFollowerDialog;
