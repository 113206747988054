import { useMutation, useQuery } from '@apollo/client';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { sv as locale } from '@norban/locale';
import React, { useCallback, useMemo } from 'react';

import {
  BofHomeOverviewDevicesDocument,
  BofHomeOverviewHomeDocument,
  BofHomeOverviewUpdateHomeDocument,
} from '../../../generated/backend/graphql';
import usePopupAlert from '../../../hooks/usePopupAlert';

const L = locale.backoffice;

type Props = {
  homeId: string;
  userId?: string;
};

export default function HomeOverview({ homeId, userId = undefined }: Props) {
  const { showPopupAlert, PopupAlert } = usePopupAlert();

  const { data, loading } = useQuery(BofHomeOverviewHomeDocument, {
    variables: {
      homeId,
    },
  });

  const { data: dataDevices } = useQuery(BofHomeOverviewDevicesDocument, {
    variables: {
      userId: `${userId}`,
    },
    skip: !userId,
  });

  const [updateHome] = useMutation(BofHomeOverviewUpdateHomeDocument);

  const handleUpdateActivityHandledAt = useCallback(async () => {
    try {
      await updateHome({
        variables: {
          homeId,
          input: { activityHandledAt: new Date().toISOString() },
        },
      });
    } catch (e) {
      showPopupAlert(L.errors.general, 'error');
    }
  }, [homeId, showPopupAlert, updateHome]);

  const activity = useMemo(() => {
    if (loading) {
      return {
        followers: { count: '', date: '' },
        homeMessages: { count: '', date: '' },
      };
    }

    const followers = data?.home.activity?.followers ?? [];
    const homeMessages = data?.home.activity?.homeMessages ?? [];

    return {
      followers: {
        count: `${followers.length}`,
        date: followers[0]
          ? new Intl.DateTimeFormat('sv-SE', {
              day: 'numeric',
              month: 'short',
              year: 'numeric',
            }).format(new Date(followers[0].createdAt))
          : '-',
      },
      homeMessages: {
        count: `${homeMessages.length}`,
        date: homeMessages[0]
          ? new Intl.DateTimeFormat('sv-SE', {
              day: 'numeric',
              month: 'short',
              year: 'numeric',
            }).format(new Date(homeMessages[0].createdAt))
          : '-',
      },
    };
  }, [data, loading]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Stack spacing={3}>
            <Card>
              <CardHeader
                title={L.homeOverview.activity}
                action={
                  <Button
                    color="secondary"
                    size="small"
                    startIcon={<AutorenewIcon />}
                    variant="contained"
                    onClick={handleUpdateActivityHandledAt}
                  >
                    {L.homeOverview.reset}
                  </Button>
                }
              />
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Typography>{`${L.homeOverview.newFollowers}: ${activity.followers.count}`}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>{activity.followers.date}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>{`${L.homeOverview.newGreetings}: ${activity.homeMessages.count}`}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>{activity.homeMessages.date}</Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Card>
              <CardHeader title={L.homeOverview.notes} />
              <CardContent>{data?.home.notes}</CardContent>
            </Card>
            <Card>
              <CardHeader title={L.homeOverview.salesProcess} />
              <CardContent>
                <Typography>{`${L.homeOverview.agent}: ${data?.home?.assignedAgent?.name ?? '-'}`}</Typography>
                <Typography>{`${L.homeOverview.status}: ${data?.home?.state ? L.enumerations.HomeState[data?.home?.state] : '-'}`}</Typography>
                <Typography>{`${L.homeOverview.salesStatus}: ${data?.home?.salesProcessStatus ? L.enumerations.SalesProcessStatus[data?.home?.salesProcessStatus] : '-'}`}</Typography>
              </CardContent>
            </Card>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack spacing={3}>
            <Card>
              <CardHeader title={L.homeOverview.user} />
              <CardContent>
                <Typography>{`${L.name}: ${data?.home?.user?.name ?? '-'}`}</Typography>
                <Typography>{`${L.email}: ${data?.home?.user?.email ?? '-'}`}</Typography>
                <Typography>{`${L.phone}: ${data?.home?.user?.phone ?? '-'}`}</Typography>
              </CardContent>
            </Card>
            <Card>
              <CardContent>
                <Typography>{`${L.homeOverview.homeCreated}: ${
                  data?.home?.created
                    ? new Intl.DateTimeFormat('sv-SE', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                      }).format(new Date(data?.home?.created))
                    : '-'
                }`}</Typography>
                <Typography>{`${L.homeOverview.usedApp}: ${dataDevices?.devices && dataDevices?.devices.length > 0 ? L.yes : L.no}`}</Typography>
              </CardContent>
            </Card>
          </Stack>
        </Grid>
      </Grid>

      <PopupAlert />
    </>
  );
}
