import { useApolloClient, useMutation } from '@apollo/client';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
} from '@mui/material';
import { sv as locale } from '@norban/locale';
import React, { useState } from 'react';

import {
  BofCreateUserDocument,
  TenureType,
} from '../../../generated/backend/graphql';

interface Props {
  open: boolean;
  onClose: (userId?: string) => void;
  withHomeDefault?: boolean;
}

const CreateUserDialog = ({
  open,
  onClose,
  withHomeDefault = false,
}: Props) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [withHome, setWithHome] = useState(withHomeDefault);
  const [tenure, setTenure] = useState(TenureType.HousingCooperative);
  const [snackbarOpen, showSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const L = locale.backoffice;

  const apolloClient = useApolloClient();

  const [createUser] = useMutation(BofCreateUserDocument, {
    onCompleted: async () => {
      setSnackbarMessage('User created');
      showSnackbar(true);

      // Reset the cache to update the user list
      await apolloClient.resetStore();
    },
    onError: err => {
      console.error(err);
      setSnackbarMessage('Failed to create user');
      showSnackbar(true);
    },
  });

  const clearForm = () => {
    setName('');
    setEmail('');
    setPhone('');
    setWithHome(withHomeDefault);
    setTenure(TenureType.HousingCooperative);
  };

  const submit = async (evt: { preventDefault: () => void }) => {
    evt.preventDefault();

    const input = {
      name,
      email: email === '' ? null : email,
      personalNumber: '000000-0000',
      phone,
    };

    const homeInput = withHome
      ? {
          tenure,
        }
      : null;

    const result = await createUser({
      variables: {
        input,
        homeInput,
        options: { client: 'BOF' },
      },
    });

    onClose(result.data?.createUser?.id ?? undefined);
    clearForm();
  };

  const cancel = () => {
    clearForm();
    onClose();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => onClose()}
        aria-labelledby="form-dialog-title"
      >
        <form onSubmit={submit}>
          <DialogTitle>{L.createNewUser.title}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label={L.name}
              value={name}
              onChange={evt => setName(evt.target.value)}
              fullWidth
            />

            <TextField
              margin="dense"
              label={L.email}
              type="email"
              value={email}
              onChange={evt => setEmail(evt.target.value)}
              fullWidth
            />

            <TextField
              margin="dense"
              label={L.phone}
              type="phone"
              value={phone}
              onChange={evt => setPhone(evt.target.value)}
              fullWidth
            />

            <FormControl component="fieldset" margin="normal">
              <RadioGroup
                aria-label="Med eller utan hem"
                name="withHome"
                value={withHome}
                onChange={evt => setWithHome(evt.target.value === 'true')}
                row
              >
                <FormControlLabel
                  value
                  control={<Radio color="primary" />}
                  label="Med bostad"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio color="primary" />}
                  label="Utan bostad"
                  labelPlacement="end"
                />
              </RadioGroup>
            </FormControl>
            {withHome}

            <FormControl component="fieldset" margin="normal">
              <RadioGroup
                aria-label="Välj bostadstyp"
                name="tenure"
                value={tenure}
                onChange={evt => setTenure(evt.target.value as TenureType)}
                row
              >
                <FormControlLabel
                  value={TenureType.GenericHouse}
                  control={<Radio color="primary" />}
                  label={L.enumerations.Tenure[TenureType.GenericHouse]}
                  labelPlacement="end"
                  disabled={!withHome}
                />
                <FormControlLabel
                  value={TenureType.HousingCooperative}
                  control={<Radio color="primary" />}
                  label={L.enumerations.Tenure[TenureType.HousingCooperative]}
                  labelPlacement="end"
                  disabled={!withHome}
                />
                <FormControlLabel
                  value={TenureType.ShareInHousingAssociation}
                  control={<Radio color="primary" />}
                  label={
                    L.enumerations.Tenure[TenureType.ShareInHousingAssociation]
                  }
                  labelPlacement="end"
                  disabled={!withHome}
                />
                <FormControlLabel
                  value={TenureType.Coownership}
                  control={<Radio color="primary" />}
                  label={L.enumerations.Tenure[TenureType.Coownership]}
                  labelPlacement="end"
                  disabled={!withHome}
                />
                <FormControlLabel
                  value={TenureType.Condominium}
                  control={<Radio color="primary" />}
                  label={L.enumerations.Tenure[TenureType.Condominium]}
                  labelPlacement="end"
                  disabled={!withHome}
                />
                <FormControlLabel
                  value={TenureType.Cottage}
                  control={<Radio color="primary" />}
                  label={L.enumerations.Tenure[TenureType.Cottage]}
                  labelPlacement="end"
                  disabled={!withHome}
                />
                <FormControlLabel
                  value={TenureType.Project}
                  control={<Radio color="primary" />}
                  label={L.enumerations.Tenure[TenureType.Project]}
                  labelPlacement="end"
                  disabled={!withHome}
                />
              </RadioGroup>
            </FormControl>
          </DialogContent>

          <DialogActions>
            <Button onClick={cancel} color="primary">
              {L.cancel}
            </Button>

            <Button onClick={submit} color="primary">
              {L.create}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        onClose={() => showSnackbar(false)}
        message={snackbarMessage}
        autoHideDuration={5000}
      />
    </>
  );
};

export default CreateUserDialog;
