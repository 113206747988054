import { ApolloError, useMutation } from '@apollo/client';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Card,
  CardContent,
  IconButton,
  Stack,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  TextField,
  DialogActions,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React, { useCallback, useState } from 'react';

import UserAutocomplete from '../../components/UserAutocomplete';
import { BofAgentsCardUpdateAreaDocument } from '../../generated/backend/graphql';
import usePopupAlert from '../../hooks/usePopupAlert';

type Row = {
  id: string;
  name: string;
};

type Props = {
  areaId: string;
  agents: { id: string; name?: string | null }[];
};

const AgentsCard = ({ areaId, agents }: Props) => {
  const [selectedAgentId, setSelectedAgentId] = useState<string | null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [confirmId, setConfirmId] = useState('');
  const { PopupAlert, showPopupAlert } = usePopupAlert();

  const [updateArea] = useMutation(BofAgentsCardUpdateAreaDocument);

  const handleDeleteAgent = useCallback(async () => {
    try {
      if (!deleteId) {
        throw new Error('No agent to delete');
      }

      await updateArea({
        variables: {
          input: {
            assignedAdminIds: [
              ...agents
                .filter(agent => agent.id !== deleteId)
                .map(agent => agent.id),
            ],
          },
          id: areaId,
        },
      });
    } catch (error) {
      const ae = error as ApolloError;
      showPopupAlert(ae.message, 'error');
    }
  }, [deleteId, updateArea, agents, areaId, showPopupAlert]);

  const handleAddAgent = useCallback(async () => {
    try {
      if (!selectedAgentId) {
        throw new Error('No selected area');
      }

      await updateArea({
        variables: {
          input: {
            assignedAdminIds: [
              ...agents.map(agent => agent.id),
              selectedAgentId,
            ],
          },
          id: areaId,
        },
      });
    } catch (error) {
      const ae = error as ApolloError;
      showPopupAlert(ae.message, 'error');
    } finally {
      setSelectedAgentId(null);
    }
  }, [selectedAgentId, updateArea, agents, areaId, showPopupAlert]);

  const columns: GridColDef<Row>[] = [
    {
      field: 'id',
      headerName: 'Id',
      type: 'string',
      width: 80,
    },
    {
      field: 'name',
      headerName: 'Namn',
      type: 'string',
      width: 250,
    },
    {
      field: 'delete',
      headerName: 'Ta bort',
      type: 'string',
      sortable: false,
      filterable: false,
      width: 80,
      renderCell: ({ row }) => (
        <IconButton
          aria-label="Ta bort"
          onClick={e => {
            e.stopPropagation();
            setDeleteId(row.id);
            setDeleteDialogOpen(true);
          }}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  const rows: Row[] = agents.map(({ id, name }) => ({
    id: id ?? '',
    name: name ?? '',
  }));

  return (
    <>
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Bekräfta genom att skriva in id</DialogTitle>
        <DialogContent>
          <Box mb={1}>
            <form noValidate>
              <TextField
                type="number"
                label="-- {L.confirmId}"
                value={confirmId}
                name="confirmId"
                onChange={evt => setConfirmId(evt.target.value)}
              />
            </form>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleDeleteAgent();
              setDeleteId('');
              setConfirmId('');
              setDeleteDialogOpen(false);
            }}
            color="primary"
            disabled={confirmId !== deleteId}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Card>
        <CardContent>
          <Stack alignItems="center" direction="row" mb={2} spacing={2}>
            <UserAutocomplete
              fullWidth
              limitToUserRoles={['admin']}
              selectedUserId={selectedAgentId}
              onSelectedUserIdChanged={id => setSelectedAgentId(id)}
            />
            <Button
              disabled={!selectedAgentId}
              sx={{ flexShrink: 0 }}
              onClick={handleAddAgent}
            >
              Lägg till
            </Button>
          </Stack>

          <DataGrid
            autoHeight
            columns={columns}
            rows={rows}
            getRowHeight={() => 'auto'}
          />
        </CardContent>
      </Card>
      <PopupAlert />
    </>
  );
};

export default AgentsCard;
