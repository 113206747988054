import { makeStyles } from '@mui/styles';
import React from 'react';

import UserAutocomplete from '../../../components/UserAutocomplete';

const useStyles = makeStyles(() => ({
  userAutocomplete: {
    fontSize: 15,
    margin: '8px 0px',
  },
}));

type Props = {
  disabled: boolean;
  label?: string;
  userId: string | null;
  onUserIdChanged: (user: string | null) => void;
};

export const FilterAdminSelect = ({
  label = undefined,
  disabled,
  userId,
  onUserIdChanged,
}: Props) => {
  const classes = useStyles();

  return (
    <UserAutocomplete
      className={classes.userAutocomplete}
      fullWidth
      label={label}
      disabled={disabled}
      selectedUserId={userId}
      size="small"
      onSelectedUserIdChanged={onUserIdChanged}
      limitToUserRoles={['admin']}
    />
  );
};
