import { Card, CardContent, CardHeader } from '@mui/material';
import { sv as locale } from '@norban/locale';
import React from 'react';

import UserAutocomplete from '../../../../components/UserAutocomplete';
import { BofHomeProfileHomeFragment } from '../../../../generated/backend/graphql';
import { DispatchType } from '../../hooks/useHomeProfileReducer';

const L = locale.backoffice.homeProfileRealtorCard;

type Props = {
  home: BofHomeProfileHomeFragment;
  dispatch: DispatchType;
};

const RealtorCard = ({ home, dispatch }: Props) => {
  return (
    <Card>
      <CardHeader title={L.title} />
      <CardContent>
        <UserAutocomplete
          limitToUserRoles={['admin']}
          selectedUserId={home?.assignedAgentUserId ?? null}
          onSelectedUserIdChanged={userId => {
            dispatch({ key: 'assignedAgentUserId', value: userId });
          }}
        />
      </CardContent>
    </Card>
  );
};

export default RealtorCard;
