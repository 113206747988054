import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
} from '@mui/material';
import { Stack } from '@mui/system';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { sv as locale } from '@norban/locale';
import React, { useMemo, useState } from 'react';

import { BofCrmActionDialogFragment } from '../generated/backend/graphql';
import useSession from '../hooks/useSession';

import UserAutocomplete from './UserAutocomplete';

type ContentProps = {
  entry?: BofCrmActionDialogFragment;
  onSubmit: (entry: Partial<BofCrmActionDialogFragment>) => void;
  onClose: () => void;
};

const CrmActionDialogContent = ({
  entry: originalEntry = undefined,
  onSubmit,
  onClose,
}: ContentProps) => {
  const L = locale.backoffice;

  const isNewEntry = !originalEntry;

  const { userId } = useSession();

  const [modifiedEntry, setModifiedEntry] = useState<
    Partial<BofCrmActionDialogFragment>
  >(
    // New entries should have the current user as default
    isNewEntry
      ? {
          assignedUser: { id: `${userId}` },
        }
      : {},
  );
  const entry = useMemo(() => {
    return {
      ...originalEntry,
      ...modifiedEntry,
    };
  }, [originalEntry, modifiedEntry]);

  return (
    <>
      <DialogTitle>
        {isNewEntry ? L.crmActions.addAction : L.crmActions.editAction}
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2} mt={1}>
          <TextField
            autoFocus
            margin="dense"
            label="Action"
            value={entry.action}
            onChange={evt => {
              setModifiedEntry({ ...modifiedEntry, action: evt.target.value });
            }}
            fullWidth
          />
          <DatePicker
            format="yyyy-MM-dd"
            label={L.date}
            value={entry.targetDate ? new Date(entry.targetDate) : new Date()}
            onChange={value =>
              setModifiedEntry({
                ...modifiedEntry,
                targetDate: value?.toISOString() ?? undefined,
              })
            }
            slotProps={{ textField: { margin: 'dense' } }}
          />
          <UserAutocomplete
            textFieldProps={{ margin: 'dense' }}
            selectedUserId={entry.assignedUser?.id ?? null}
            onSelectedUserIdChanged={assignedUserId =>
              setModifiedEntry({
                ...modifiedEntry,
                assignedUser: assignedUserId
                  ? { id: `${assignedUserId}` }
                  : null,
              })
            }
            limitToUserRoles={['admin']}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={entry.completed}
                onChange={(_evt, checked) => {
                  setModifiedEntry({
                    ...modifiedEntry,
                    completed: checked,
                  });
                }}
              />
            }
            label={L.crmActions.handled}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose();
          }}
          color="primary"
        >
          {L.cancel}
        </Button>
        <Button
          disabled={Object.keys(modifiedEntry).length === 0}
          onClick={() => {
            onSubmit(modifiedEntry);
            onClose();
          }}
          color="primary"
        >
          {isNewEntry ? L.add : L.submit}
        </Button>
      </DialogActions>
    </>
  );
};

type Props = {
  open: boolean;
  entry?: BofCrmActionDialogFragment;
  onSubmit: (entry: Partial<BofCrmActionDialogFragment>) => void;
  onClose: () => void;
};

const CrmActionDialog = ({
  open,
  entry = undefined,
  onSubmit,
  onClose,
}: Props) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <CrmActionDialogContent
        entry={entry}
        onSubmit={onSubmit}
        onClose={onClose}
      />
    </Dialog>
  );
};

export default CrmActionDialog;
