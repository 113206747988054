import { useQuery } from '@apollo/client';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  addDays,
  addMonths,
  differenceInCalendarDays,
  endOfDay,
  startOfDay,
  subMonths,
} from 'date-fns';
import React, { useMemo, useState } from 'react';
import { DateParam, useQueryParam, withDefault } from 'use-query-params';

import QueryError from '../../../components/QueryError';
import QueryLoading from '../../../components/QueryLoading';
import {
  BofStatsHomesDocument,
  BofStatsHomesQuery,
  HomeState,
} from '../../../generated/backend/graphql';

import LineChart from './LineChart';
import StatsDateSelector from './StatsDateSelector';

type StatType = {
  created: string | null;
  day: string;
  count: number;
};

const useStyles = makeStyles({
  chart: {
    paddingTop: 24,
    '& .ct-series-a': {
      '& .ct-line': {
        stroke: '#D87762',
      },
      '& .ct-point': {
        stroke: '#D87762',
      },
    },
  },
});

const StatsHomes = () => {
  const timeAtMount = useMemo(() => new Date(), []);
  const [startDate, setStartDate] = useQueryParam(
    'startDate',
    withDefault(DateParam, startOfDay(subMonths(timeAtMount, 6))),
  );
  const [endDate, setEndDate] = useQueryParam(
    'endDate',
    withDefault(DateParam, endOfDay(timeAtMount)),
  );
  const [accumulated, setAccumulated] = useState();

  const classes = useStyles();

  const { loading, error, data } = useQuery(BofStatsHomesDocument, {
    variables: { from: startDate.toString(), to: endDate.toString() },
  });

  if (loading) {
    return <QueryLoading />;
  }

  if (error) {
    return <QueryError error={error} data={data} />;
  }

  const { publicHomes, homes } = data ?? {};

  const arrangeHomes = (
    arr: BofStatsHomesQuery['publicHomes'] | BofStatsHomesQuery['homes'],
  ) =>
    arr
      .filter(home => {
        if (!home) {
          return false;
        }
        const date = new Date(home.created ?? '');
        return date > startDate && date < endDate;
      })
      .sort((a, b) => a.created.localeCompare(b.created));

  const sortedPubHomes = arrangeHomes(publicHomes ?? []);
  const sortedHomes = arrangeHomes(homes ?? []);

  // Option 1 - stats per day
  const stats = sortedHomes.reduce<StatType[]>((agg, item) => {
    const { created } = item;
    const date = new Date(created);
    const prevDateStr = agg[agg.length - 1]?.created;
    const prevDate =
      agg.length > 0 && prevDateStr ? new Date(prevDateStr) : null;
    const calendarDaysDiff = prevDate
      ? differenceInCalendarDays(date, prevDate)
      : 0;
    const diff = calendarDaysDiff > 0 ? calendarDaysDiff - 1 : 0;
    const day = date.toString().substring(4, 10);
    const prevItem = agg.find(i => i.day === day);
    const count = prevItem ? prevItem.count + 1 : 1;
    const prev = prevItem ? agg.filter(i => i.day !== day) : agg;
    // Pad with entries to make sure there are no gaps
    const padding = Array(diff)
      .fill(undefined)
      .map((_, i) => {
        const newDay = prevDate
          ? addDays(prevDate, i + 1)
              .toString()
              .substring(4, 10)
          : '';
        return { day: newDay, count: 0, created: null };
      });
    return [...prev, ...padding, { day, count, created }];
  }, []);

  // Option 2 - accumulated stats
  const accStats = stats.reduce<StatType[]>((acc, item, index) => {
    const prevItem = acc[index - 1];
    const prevCount = prevItem ? prevItem.count : 0;
    return [...acc, { ...item, count: item.count + prevCount }];
  }, []);

  const renderStats = accumulated ? accStats : stats;
  const graphData: {
    labels?: string[];
    series: number[][];
    count?: number;
    days?: number;
    mean?: string;
  } = { series: [] };

  const max =
    renderStats.length > 0
      ? Math.max(...renderStats.map(stat => stat.count))
      : 0;
  const total = sortedHomes.length;
  const SPMs = sortedHomes.filter(h => h.state === HomeState.PremarketLight);
  const converted = sortedHomes.filter(
    h =>
      h.state === HomeState.Premarket ||
      h.state === HomeState.OpenMarket ||
      h.state === HomeState.Sold,
  );
  const totalPublic = sortedPubHomes.length;
  const SPMcount = SPMs ? SPMs.length : 0;
  const convertedCount = converted.length;

  graphData.labels = renderStats.map(i => i.day);
  graphData.series = [renderStats.map(i => i.count)];
  graphData.count = total;
  const days = renderStats.length;
  graphData.days = days;
  graphData.mean = days > 0 ? (total / renderStats.length).toFixed(2) : '0';

  const convTotal = `${total ? ((convertedCount * 100) / total).toFixed(2) : 0}%`;
  const convPublic = `${
    totalPublic ? ((convertedCount * 100) / totalPublic).toFixed(2) : 0
  }%`;

  const options = {
    high: max,
    low: 0,
    axisX: {
      labelInterpolationFnc(value: unknown, index: number) {
        return index % 10 === 0 ? value : null;
      },
    },
    height: '50vh',
    width: '100%',
  };

  return (
    <>
      <StatsDateSelector
        startDate={startDate}
        endDate={endDate}
        accumulated={accumulated}
        onChangeCombinedDates={(start: Date, end: Date) => {
          if (addMonths(start, 6) < end) {
            setStartDate(subMonths(end, 6));
          } else {
            setStartDate(start);
          }
          setEndDate(end);
        }}
        onChangeStartDate={(date: Date) => {
          const sixMonthsAhead = addMonths(date, 6);
          if (sixMonthsAhead < endDate) {
            setEndDate(sixMonthsAhead);
          }
          setStartDate(date);
        }}
        onChangeEndDate={(date: Date) => {
          const sixMonthsSince = subMonths(date, 6);
          if (sixMonthsSince > startDate) {
            setStartDate(sixMonthsSince);
          }
          setEndDate(date);
        }}
        onChangeAccumulated={setAccumulated}
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>total</TableCell>
            <TableCell>public</TableCell>
            <TableCell>days</TableCell>
            <TableCell>mean</TableCell>
            <TableCell>max</TableCell>
            <TableCell>SPM</TableCell>
            <TableCell>converted</TableCell>
            <TableCell>Conv total</TableCell>
            <TableCell>Conv public</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{total}</TableCell>
            <TableCell>{totalPublic}</TableCell>
            <TableCell>{graphData.days}</TableCell>
            <TableCell>{graphData.mean}</TableCell>
            <TableCell>{max}</TableCell>
            <TableCell>{SPMcount}</TableCell>
            <TableCell>{convertedCount}</TableCell>
            <TableCell>{convTotal}</TableCell>
            <TableCell>{convPublic}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <LineChart className={classes.chart} data={graphData} options={options} />
    </>
  );
};

export default StatsHomes;
