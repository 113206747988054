import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import React from 'react';

import UserAutocomplete from './UserAutocomplete';

type Props = {
  cancel: string;
  confirm: string;
  content: React.ReactNode;
  dense?: boolean;
  open: boolean;
  selectedUserId: string | null;
  title: string;
  onCancel: () => void;
  onConfirm: (userId: string) => void;
  onSelectedUserIdChanged: (userId: string | null) => void;
  limitToUserRoles?: ('admin' | 'user' | 'banned')[];
};

const UserAutocompleteDialog = ({
  cancel,
  confirm,
  content,
  dense = true,
  open,
  selectedUserId,
  title,
  onSelectedUserIdChanged,
  onCancel,
  onConfirm,
  limitToUserRoles = undefined,
}: Props) => {
  return (
    <Dialog open={open} onClose={onCancel} maxWidth="xs" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <UserAutocomplete
          dense={dense}
          fullWidth
          limitToUserRoles={limitToUserRoles}
          selectedUserId={selectedUserId}
          onSelectedUserIdChanged={onSelectedUserIdChanged}
        />
        {content}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          {cancel}
        </Button>
        <Button
          onClick={() => {
            onConfirm(selectedUserId!);
          }}
          disabled={!selectedUserId}
          color="primary"
        >
          {confirm}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserAutocompleteDialog;
